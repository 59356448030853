import React from "react";
import ReactModal from "react-modal";

// Make sure to bind modal to your appElement for accessibility
ReactModal.setAppElement("#root");

function Modal(props) {
  const {
    open,
    onClose,
    children,
    shouldCloseOnEsc = true,
    shouldFocusAfterRender = true,
    shouldCloseOnOverlayClick = true,
    shouldReturnFocusAfterClose = true,
  } = props;

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldFocusAfterRender={shouldFocusAfterRender}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      <div className="modal-content">
        <button onClick={onClose} className="modal-close-button">✖</button>
        {children}
      </div>
    </ReactModal>
  );
}

export default Modal;
