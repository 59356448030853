import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  const socialLinks = [
    {
      name: 'YouTube',
      icon: (
        <img src="../images/youtube.png" className="icon-footer" alt="YouTube" />
      ),
      href: 'https://www.youtube.com/@Regaiworld'
    },
    {
      name: 'TikTok',
      icon: (
        <img src="../images/tik-tok.png" className="icon-footer" alt="TikTok" />
      ),
      href: 'https://www.tiktok.com/@regenerativeai'
    },
    {
      name: 'Telegram',
      icon: (
        <img src="../images/telegram.png" className="icon-footer" alt="Telegram" />
      ),
      href: 'https://t.me/regenerativeai'
    },
    {
      name: 'X (Twitter)',
      icon: (
       <img src="../images/twitter.png" className="icon-footer" alt="Twitter" />
      ),
      href: 'https://x.com/RegAIWorld'
    }
  ];

  const navLinks = [
    // { text: 'HOME', href: '#' },
    // { text: 'OUR PROJECTS', href: '#' },
    // { text: 'OUR TEAM', href: '#' },
    // { text: 'NEWS FAQ', href: '#' },
    // { text: 'DOCUMENTS', href: '#' }
  ];

  const bottomLinks = [
    // { text: 'Job & Career', href: '#' },
    // { text: 'Terms and Condition', href: '#' },
    // { text: 'Help Center', href: '#' }
  ];

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="white-background">
      <footer className="text-white flex flex-col items-center py-8 px-4 footer-sec">
        {/* Logo and Token Address */}
        <div className="flex flex-col items-center mb-8">
          <Link to="/" className="Link">
            <img src="../images/logo_3.jpeg" className="logo-footer" alt="Logo" />
          </Link>
          {/* <Link to="/">
            <img src="../images/logo_png.png" className="logo" alt="Logo" />
          </Link> */}
          <p className="token-add">Reg Ai: 0xa3A2d98c55DA6ef9C732b44d2E9BB8D9Ea719362</p>
        </div>

        {/* Social Icons */}
        <div className="flex gap-8 mb-8 justify-center">
          {socialLinks.map((social, index) => (
            <div
              key={index}
              onClick={() => openInNewTab(social.href)}
              className="social-icon hover:bg-gray-200 transition-colors"
              aria-label={social.name}
            >
              {social.icon}
            </div>
          ))}
        </div>

        {/* Navigation Links */}
        <nav className="flex flex-col items-center gap-4 mb-8">
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.href}
              className="hover:text-gray-300 transition-colors font-semibold tracking-wide"
              onClick={(e) => { if (!link.href) e.preventDefault(); }} // Prevent default if no href
            >
              {link.text}
            </Link>
          ))}
        </nav>

        {/* Divider Line */}
        <div className="w-full max-w-4xl border-t border-gray-800 mb-6"></div>

        {/* Copyright and Bottom Links */}
        <div className="w-full max-w-4xl flex flex-col items-center gap-4">
          <p className="text-center text-sm">
            Copyright © 2024. All rights reserved by Regenerative AI.
          </p>
          <div className="flex flex-wrap justify-center gap-8 text-sm">
            {bottomLinks.map((link, index) => (
              <Link
                key={index}
                to={link.href}
                className="hover:text-gray-300 transition-colors"
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>

        {/* Scroll to Top Button */}
        <button 
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="fixed bg-white bottom-6 right-6 p-3 rounded-lg transition-colors"
          aria-label="Scroll to top"
          style={{color:'black', border: '1px solid black', zIndex: '10000'}}
        >
          <svg 
            viewBox="0 0 24 24" 
            className="w-5 h-5 fill-current"
          >
            <path d="M12 4l8 8h-6v8h-4v-8H4z" />
          </svg>
        </button>
      </footer>
    </div>
  );
};

export default Footer;
