import React from 'react';

const WhyChooseUs = () => {
  return (
    <div className="white-background">
      <div className="why-choose-us-section">
        <div className="wrap">
          <h2 className="why-us">Why Choose ReGen AI?</h2>
          <br/>
          <div className="flex w-ful line"></div>
          <br/>

          <div className="why-us-below">
            <p>Blending innovation, AI-driven engagement, and a forward-thinking gaming experience, ReGen AI makes crypto staking and earning engaging, rewarding, and undeniably fun. Ask, stake, play, and thrive with us, because earning in crypto should never be boring.</p>
          </div>
          <div className="features">
            <Feature
              title="Earn as You Learn"
              description="Interact with our AI and get rewarded in tokens for every question asked."
            />
            <Feature
              title="Staking Rewards"
              description="Stake your tokens and earn up to 25% annually. For example, staking 10,000 tokens in Silver Tier for 3 months yields 120 tokens."
            />
            <Feature
              title="Tiered Excellence"
              description="Choose your staking category; Bronze, Silver, Gold, or Platinum—and watch your earnings grow, from quarterly to annual rewards."
            />
            <Feature
              title="Meta Gameplay Rewards"
              description="Excited about gaming? Our Meta gameplay feature allows users to generate rewards through immersive gaming experiences."
            />
            <Feature
              title="Exciting Perks"
              description="1% bonus tokens for referrals, loyalty boosts for long-term stakers, and governance power with voting rights."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Feature = ({ title, description }) => {
  return (
    <div className="feature">
      <h3 className="feature-title">{title}</h3>
      <p className="feature-description">{description}</p>
    </div>
  );
};

export default WhyChooseUs;
