import React from "react";
import { Link, NavLink } from "react-router-dom";

const Hero = () => {

  const openPdfInNewTab = () => {
    const pdfUrl = require("../../assets/images/white-paper.pdf");

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "white-paper.pdf"; // Set the desired file name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


return (
    <div class="white-background">
        <div className="hero-section flex">
            <div className="wrap wrapWidth flex items-center justify-center gap-8">
                <div className="left-side flex flex-1 flex-col gap-8">
                    <h1 className="slug">
                        Welcome To The <span>Regenerative A.I</span> World
                    </h1>
                    <p className="home-desc">
                        Optimum utilization of AI technology to discover potential DEX tokens.{" "}
                    </p>
                    <div className="flex   gap-4">
                        <button onClick={openPdfInNewTab} style={{ background: "white", color: "black", display: 'flex', alignItems: 'center' }} className="btn button">
                            Download White Paper 
                            <span style={{ marginLeft: '8px' }}>
                                <img src="../images/download_icon.svg" alt="Download Icon" />
                            </span>
                        </button>
                        {/* <a href="#faq" >
                            <button style={{ background:"white", color:"black" }}  className="btn button">Learn More</button>
                        </a>  */}
                    </div>
                </div>
                <div className="right-side flex flex-1 justify-end">
                    <div className="flex flex-col">
                        <img src="../images/Mascot.png" class="mascot-img" alt="Mascot" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Hero;
