import React from 'react';

const TokenomicsDisplay = () => {
  const tokenomics = [
    { name: 'Pre-launch Private Sale', percentage: 10, color: 'bg-violet-500' },
    { name: 'Staking Rewards', percentage: 40, color: 'bg-pink-500' },
    { name: 'Liquidity', percentage: 40, color: 'bg-indigo-500' },
    { name: 'Marketing', percentage: 5, color: 'bg-white' },
    { name: 'Team & Development', percentage: 5, color: 'bg-blue-500' }
  ];

  return (
    <div id="tokenomics" className="white-background">
      <div className="tokenomics-section relative">
        <div className="flex-start space-y-6 inner-sec">
          <h1 className="text-2xl font-bold bg-liner-green">Tokenomics Distribution</h1>
        </div>

        <div className="space-y-6">
          <div className="relative h-4 bg-gray-800 rounded-full overflow-hidden flex">
            {tokenomics.map((item, index) => (
              <div
                key={item.name}
                className={`${item.color} h-full transition-all duration-500`}
                style={{
                  width: `${item.percentage}%`,
                  marginLeft: index === 0 ? '0' : undefined
                }}
              />
            ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
            {tokenomics.map((item) => (
              <div 
                key={item.name} 
                className="bg-[#13132b] rounded-lg p-4 transition-all duration-300 hover:bg-gray-700 relative z-10"
              >
                <div className="flex items-center space-x-3">
                  <div className={`w-4 h-4 rounded-full ${item.color}`} />
                  <div>
                    <h3 className="font-medium text-white">{item.name}</h3>
                    <p className="text-xl font-bold text-white">{item.percentage}%</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Optional Total Supply Section */}
          {/* <div className="mt-8 text-center bg-[#13132b] rounded-lg p-4 transition-all duration-300 hover:bg-gray-700">
            <p className="text-lg font-medium text-white">Total Supply</p>
            <p className="text-3xl font-bold text-white">100%</p>
          </div> */}

          <div className="absolute inset-0 rounded-xl p-1 bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500 opacity-20 z-0"></div>
        </div>
      </div>
    </div>
  );
};

export default TokenomicsDisplay;
