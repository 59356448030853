import React, { useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";
import "./style.css"; // Ensure you have this CSS for styling

const AIBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: "user" }]);
      setInput("");

      // Simulate a bot response
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "I'm here to help!", sender: "bot" },
        ]);
      }, 1000);
    }
  };

  return (
    <Wrapper>
      <div className="bg-themeColor min-h-screen flex flex-col items-center">
        <div className="chat-window">
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={msg.sender}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="input-area">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSend()}
              placeholder="Type a message..."
            />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AIBot;
