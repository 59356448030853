import React, { useState } from 'react';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      id: 1,
      question: "What is RegenAI?",
      answer: (
        <>
          RegenAI is an innovative AI-based smart tool designed to revolutionize your engagement with the crypto world. It’s a platform where you can earn rewards simply by interacting with AI. RegenAI transforms boredom into opportunities, allowing you to make money while having fun. Beyond engagement rewards, RegenAI also offers staking programs with lucrative benefits. Built on Binance, our mission is to create a thriving community of like-minded individuals who believe in the power of AI and “HODL” together for a brighter, decentralized future.
        </>
      ),
    },
    {
      id: 2,
      question: "How does the “Ask to Earn” feature work?",
      answer: (
        <>
          RegenAI’s “Ask to Earn” feature rewards you for staying curious and engaged. Simply ask questions—whether they’re fun, insightful, or practical—and share your thoughts in our AI-powered chatbox. The more you interact with RegenAI, the more rewards you accumulate. It’s that simple and engaging!
        </>
      ),
    },
    {
      id: 3,
      question: "How does the staking reward system work?",
      answer: (
        <>
          Our staking reward system is designed to be both rewarding and flexible, offering three distinct tiers: Silver, Gold, and Platinum. Depending on the duration and amount of your stake, you can unlock significant rewards every six months. Choose your tier and watch your rewards grow effortlessly.
        </>
      ),
    },
    {
      id: 4,
      question: "When does RegenAI trading begin?",
      answer: (
        <>
          Exciting times are ahead! RegenAI trading is set to launch soon. Stay tuned for updates, and be ready to join the next big thing in AI-powered crypto trading.
        </>
      ),
    },
    {
      id: 5,
      question: "How are staking rewards calculated?",
      answer: (
        <div>
          <p>Our staking rewards program is structured to maximize your returns based on your participation. Here’s a quick breakdown:</p>
          <br/>
          <h3 style={{ textDecoration: 'underline', fontWeight: 'bold'}}>Staking Options:</h3>
          <ul>
            <li>- Fixed staking durations: 1, 3, 6, or 12 months.</li>
          </ul>
          <br/>
          <h3 style={{ textDecoration: 'underline', fontWeight: 'bold'}}>Reward Tiers:</h3>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border p-2">Tier</th>
                <th className="border p-2">Staked Amount (Tokens)</th>
                <th className="border p-2">Reward Rate (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2">Bronze</td>
                <td className="border p-2">1,000 - 10,000</td>
                <td className="border p-2">2%</td>
              </tr>
              <tr>
                <td className="border p-2">Silver</td>
                <td className="border p-2">10,001 - 50,000</td>
                <td className="border p-2">4%</td>
              </tr>
              <tr>
                <td className="border p-2">Gold</td>
                <td className="border p-2">50,001 - 100,000</td>
                <td className="border p-2">6%</td>
              </tr>
              <tr>
                <td className="border p-2">Platinum</td>
                <td className="border p-2">100,001+</td>
                <td className="border p-2">8%</td>
              </tr>
            </tbody>
          </table>
          <br/>
          <h3 style={{ textDecoration: 'underline', fontWeight: 'bold'}}>Reward Distribution:</h3>
          <ul>
            <li>- Quarterly Rewards: 10-15% of total rewards are distributed every three months.</li>
            <li>- Annual Rewards: 20-25% of total rewards are distributed yearly.</li>
          </ul>
          <br/>
          <h3 style={{ textDecoration: 'underline', fontWeight: 'bold'}}>Additional Incentives:</h3>
          <ul>
            <li>- Referral Program: Earn 1% for every referral.</li>
            <li>- Loyalty Program: Higher reward rates for long-term staking.</li>
            <li>- Voting Rights: Participate in governance and decision-making.</li>
          </ul>
          <br/>
          <h3 style={{ textDecoration: 'underline', fontWeight: 'bold'}}>Security Measures:</h3>
          <ul>
            <li>- Secure, audited smart contracts.</li>
            <li>- Multi-signature wallets for added protection.</li>
            <li>- Regular program audits for transparency.</li>
          </ul>

        </div>
      ),
    },
    {
      id: 5,
      question: "What happens if I unstake early?",
      answer: (
        <>
          If you unstake early, a small penalty of 5-10% is applied. This ensures fairness and encourages long-term participation while maintaining the staking program's integrity.
        </>
      ),
    },
    {
      id: 6,
      question: "What are the minimum requirements for staking?",
      answer: (
        <div>
          <ul>
            <li>- A compatible wallet.</li>
            <li>- A minimum stake of 1,000-2,000 tokens.</li>
            <li>- Active participation in network validation to maximize benefits.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 7,
      question: "How transparent is the staking process?",
      answer: (
        <>
          Transparency is at the heart of our program. Staking statistics, including total staked tokens, staking ratios, and reward distributions, are readily available on your dashboard for full visibility.
        </>
      ),
    },
    {
      id: 8,
      question: "What are the benefits of being part of the RegenAI community?",
      answer: (
        <div>
          <ul>
            <li>- An opportunity to earn while you learn and engage.</li>
            <li>- Access to staking rewards that grow with your commitment.</li>
            <li>- A chance to participate in shaping the future of AI-powered crypto solutions.</li>
            <li>- A vibrant, supportive community that thrives on innovation and collaboration.</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className="white-background">
      <div className="min-h-screen p-4 md:p-8 faq-section">
        <div className="max-w-4xl">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-liner-green">
            Frequently Asked Questions
          </h1>
          <p className="text-gray-400 mb-8">
            Do you have any kind of questions? We're here to help.
          </p>

          <div className="space-y-3">
            {faqData.map((faq, index) => (
              <div
                key={faq.id}
                className="bg-gray-800/50 rounded-lg overflow-hidden"
              >
                <div className="w-full text-white">
                  <div
                    onClick={() => setOpenIndex(openIndex === index ? null : index)}
                    className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-700/50 transition-colors"
                  >
                    <div className="flex items-center gap-3 flex-grow">
                      <span className="text-[#B2FF00]">{faq.id}.</span>
                      <span>{faq.question}</span> {/* Question text */}
                    </div>
                    <span
                      className={`text-2xl text-[#B2FF00] transition-transform duration-300 ease-in-out transform ${openIndex === index ? 'rotate-45' : 'rotate-0'}`}
                    >
                      +
                    </span>
                  </div>
                  <div
                    className={`overflow transition-all duration-300 ease-in-out ${openIndex === index ? 'max-h-none opacity-100' : 'max-h-0 opacity-0'}`}
                  >
                    <div className="p-4 text-gray-300 border-t border-gray-700">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="fixed bottom-6 right-6 bg-[#B2FF00] rounded-lg p-3 hover:bg-[#9FE600] transition-colors"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FAQSection;
