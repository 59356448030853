import React from "react";

import "./css/App.scss";
import "./index.css";

import Routing from "./routes/Routing";

function App() {
  return <Routing />;
}

export default App;
