import React, { useState, useEffect } from 'react';

const ChevronLeft = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M15 18l-6-6 6-6" />
  </svg>
);

const ChevronRight = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M9 18l6-6-6-6" />
  </svg>
);

const RoadmapSlider = () => {
  const roadmapItems = [
    { index: 1, title: 'AI Bot Testing', description: 'Testing phase for the AI bot that integrates regenerative technologies, ensuring functionality and user satisfaction.', date: 'November 2024' },
    { index: 2, title: 'Website Launch', description: 'Official launch of our new website, featuring enhanced user experience and comprehensive information about our project.', date: 'November 2024' },
    { index: 3, title: 'Token Activation', description: 'Introduction of the "Token of the Week" initiative, promoting selected tokens and increasing user engagement.', date: 'December 2024' },
    { index: 4, title: 'Private Pre-Sale', description: 'Exclusive opportunity for early investors to participate in our token pre-sale, with limited availability.', date: 'January 2025' },
    { index: 5, title: 'AI Staking Pass', description: 'Launch of the AI Staking Pass, allowing users to stake tokens for rewards and participate in governance.', date: 'February 2025' },
    { index: 6, title: 'Liquidity Addition', description: 'Increasing liquidity on exchanges to facilitate trading and improve market stability for our token.', date: 'March 2025' },
    { index: 7, title: 'REG AI Trading', description: 'Commencement of trading for the REG AI Token on various exchanges, making it accessible to the public.', date: 'April 2025' },
    { index: 8, title: 'Staking Pool', description: 'Launch of the staking pool, allowing users to earn rewards by staking their tokens in our ecosystem.', date: 'May 2025' },
    { index: 9, title: 'Coingecko Listing', description: 'Our token will be listed on CoinGecko, increasing visibility and accessibility for potential investors.', date: 'June 2025' },
    { index: 10, title: 'Coinmarketcap Listing', description: 'Official listing on CoinMarketCap, providing real-time data and insights into our token’s performance.', date: 'July 2025' },
    { index: 11, title: 'Exchange Listing', description: 'Successful listing on a major centralized exchange, further enhancing trading opportunities for our users.', date: 'August 2025' },
    { index: 12, title: 'Global AI Brand', description: 'Achieving recognition as a leading AI brand globally, focused on innovative solutions and community engagement.', date: 'September 2025' }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  
  const itemsPerPage = window.innerWidth < 768 ? 1 : 3;

  const handleNext = () => {
    if (currentIndex + itemsPerPage < roadmapItems.length) {
      setCurrentIndex(prev => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const visibleItems = roadmapItems.slice(currentIndex, currentIndex + itemsPerPage);
  
  const canGoNext = currentIndex + itemsPerPage < roadmapItems.length;
  const canGoPrev = currentIndex > 0;

  useEffect(() => {
    const timer = setInterval(() => {
      if (canGoNext) {
        handleNext();
      } else {
        setCurrentIndex(0); // Reset to the first item if at the end
      }
    }, 2500); // Change slide every 3.5 seconds

    return () => clearInterval(timer); // Cleanup on unmount
  }, [currentIndex, canGoNext]);

  return (
    <div id="roadmap" className="white-background">
      <div className="w-full py-16 road-map-section">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center mb-16">
            <h1 className="bg-liner-green text-4xl md:text-5xl font-bold">Our Roadmap</h1>
            <div className="flex gap-4">
              <button 
                onClick={handlePrev}
                disabled={!canGoPrev}
                className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors ${
                    canGoPrev 
                    ? 'solid-green-background hover:bg-gray-200 cursor-pointer' 
                    : 'bg-gray-600 cursor-not-allowed'
                }`}
              >
                <ChevronLeft />
              </button>
              <button 
                onClick={handleNext}
                disabled={!canGoNext}
                className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors ${
                    canGoNext 
                    ? 'solid-green-background hover:bg-gray-200 cursor-pointer' 
                    : 'bg-gray-600 cursor-not-allowed'
                }`}
              >
                <ChevronRight />
              </button>
            </div>
          </div>

          <div className="transition-all duration-500 ease-in-out opacity-100 transform translate-y-0 grid grid-cols-1 md:grid-cols-3 gap-6">
            {visibleItems.map((item, index) => (
              <div 
                key={currentIndex + index} 
                className="bg-[#13132b] rounded-lg p-8 shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-1 h-64 flex flex-col justify-between"
                style={{ minWidth: '300px' }} // Set a minimum width
              >
                <div className="flex flex-col gap-4">
                  <div className="text-gray-400 text-sm tracking-wider">
                    {item.date}
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-3 h-3 rounded-full bg-purple-500" />
                    <p style={{color:"#fff"}}>{item.index})</p>
                    <h3 className="text-white text-xl md:text-2xl font-bold">{item.title}</h3>
                  </div>
                  <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapSlider;
