import React from "react";
import { TelegramIcon, TwitterIcon } from "../../assets/Icons";
import Timer from "../../components/Timer";
const About = () => {
  return (
    <div className="white-background">
      <div id="aboutus" className="about-us-section flex" >
        <div className="wrap wrapWidth flex flex-col gap-12">
          <div className="main-block flex items-center justify-center gap-8">
            <div className="left-side flex flex-1">
              {/* <img src="./images/about.png" className="about-img" /> */}
              <img src="../images/Mascot.png" className="about-img" />
            </div>
            <div className="right-side flex flex-1 flex-col gap-8">
              <div className="title">About Us</div>
              <div className="flex w-ful line"></div>
              <p className="desc">
                Welcome to ReGen AI, where cutting-edge technology meets community-driven rewards in a unique fusion of AI, staking, and gaming. At ReGen AI, asking questions earns you tokens, staking unlocks powerful tiered rewards, and gaming paves the way for future profits.
                {/* <br /> */}
                {/* <br /> */}
                {/* <span className='why-us'>Why you should choose REGEN AI?</span> */}
              </p>
              {/* <p className="desc">
                - <span className="underline">Earn as You Learn:</span> Interact with our AI and get rewarded in tokens for every question asked.
                <br/>
                - <span className="underline">Staking Rewards:</span> Stake your tokens and earn up to 25% annually. For example, staking 10,000 tokens in Silver Tier for 3 months yields 120 tokens.
                <br/>
                - <span className="underline">Tiered Excellence:</span> Choose your staking category; Bronze, Silver, Gold, or Platinum—and watch your earnings grow, from quarterly to annual rewards.
                <br/>
                - <span className="underline">Meta Gameplay Rewards:</span> Excited about gaming? Our Meta gameplay feature allows users to generate rewards through immersive gaming experiences. Launching strategically during the META narrative peak, we ensure our HODLERS make the most profits.
              </p> */}
              {/* <button className="btn-dtail btn button">More Details</button> */}
            </div>
          </div>
          {/* <p className="why-us-below">Blending innovation, AI-driven engagement, and a forward-thinking gaming experience, ReGen AI makes crypto staking and earning engaging, rewarding, and undeniably fun. Ask, stake, play, and thrive with us, because earning in crypto should never be boring.</p> */}
          {/* <div className="token-box flex">
            <div className="box-left flex flex-col flex-1">
              <h1 className="slug mb-12">Token Of The Week</h1>
              <div className="detail-box flex gap-10">
                <div className="detail-left flex flex-col gap-4">
                  <div className="flex flex-col">
                    <h2 className="text-[#ef55dc] font-bold text-lg">
                      TOKEN NAME
                    </h2>
                    <p className="text-white inter font-bold text-xl">
                      Regenerative Regai
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-[#ef55dc] font-bold text-lg">Liquidity</h2>
                    <p className="text-white inter font-bold text-xl">
                      1 Million
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-[#ef55dc] font-bold text-lg">BLOCKCHAIN</h2>
                    <p className="text-white inter font-bold text-xl">
                      Ethereum
                    </p>
                  </div>
                </div>
                <div className="detail-right flex flex-col gap-4">
                  <div className="flex flex-col">
                    <h2 className="text-[#ef55dc] font-bold text-lg">
                      TOKEN PRICE
                    </h2>
                    <p className="text-white inter font-bold text-xl">
                      0.1 Usdt
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-[#ef55dc] font-bold text-lg">
                      DAILY VOLUME
                    </h2>
                    <p className="text-white inter font-bold text-xl">
                      1 Million
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-[#ef55dc] font-bold text-lg">
                      WHERE TO BUY?
                    </h2>
                    <p className="text-white inter font-bold text-xl">
                      http:dummy0123.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-right flex flex-col flex-1 gap-10">
              <div className="flex items-center gap-2 sm:flex-col flex-row">
                <h3 className="text-white text-sm">Next Token In:</h3>
                <Timer />
              </div>
              <div className="flex flex-col items-center justify-center gap-3">
                <img src="./images/favicon.png" className="fav-icon" />
                <a href="/" className="text-white inter font-light text-sm">
                  www.generativeaitoken.com
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default About;
